<template>
  <v-theme-provider dark>
    <section id="resources-description">
      <base-img
        :min-height="minHeight"
        :max-height="300"
        :gradient="gradient"
        :src="require('@/assets/resources.jpg')"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-container class="fill-height px-4 py-4">
          <v-responsive
            class="d-flex align-center mx-auto"
            height="100%"
            max-width="700"
            width="100%"
          >
            <base-heading
              size="text-h3"
              title="HVS RESOURCES"
              weight="medium"
            />
            <base-body>
              Explore HVS resources. Read blogs and articles and learn more
              about what we do.
            </base-body>
          </v-responsive>
        </v-container>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "ResourcesDescription",

  metaInfo() {
    return {
      changed: (meta) => (this.title = meta.titleChunk.toUpperCase()),
    };
  },

  provide: {
    heading: { align: "center" },
  },

  data: () => ({
    title: "",
    showCertificate: false,
  }),

  computed: {
    gradient() {
      const color = `${this.$vuetify.theme.themes.light.secondary}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "40vh" : "12vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
